import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const FooterData = (data) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'SET_FOOTER_DATA',
      preContent: data.preContent,
    });

    return () => {
      dispatch({
        type: 'RESET_FOOTER_DATA',
      });
    };
  });

  return true;
};
