import React, { useEffect, useState } from 'react';
import { SignUpLayout } from '../../components/Layout';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import { FooterData } from '../../components/Footer/FooterHook';
import handIcon from '../../assets/img/icons/hand.svg';
import helloIllustration from '../../assets/img/illustrations/hello.jpg';
import queryString from 'query-string';
import has from 'lodash/has';
// import { Helmet } from "react-helmet"
// import isMobile from 'ismobilejs';
//
// const isPhoneDevice =  typeof window !== 'undefined' && window
//   ? isMobile(window.navigator).phone
//   : false;

export default ({ location }) => {
  const addressSearchId = 'addressSearch';
  const [fromMobileApp, setFromMobileApp] = useState(false);
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const validParams = queryString.parse(location.search);

    if (has(validParams, 'platform')) {
      setPlatform(validParams.platform);
    }
  }, [location.search]);

  useEffect(() => {
    setFromMobileApp(
      platform && (platform === 'ANDROID' || platform === 'IOS') ? true : false,
    );
  }, [platform]);

  // Add footer illustration
  FooterData({
    preContent: (
      <div className="ml-auto mr-auto block text-align-center mt-10">
        <img
          src={helloIllustration}
          alt="Alacaza - Rejoignez vos voisins"
          className="illustration-hello ml-auto mr-auto"
        />
      </div>
    ),
  });

  return (
    <SignUpLayout fromMobileApp={fromMobileApp}>
      <section className="step address-search container row pl-5-md pr-5-md">
        <div className="text-align-center mt-2">
          <img src={handIcon} alt="Alacaza - Inscription" />
        </div>

        <div className="text-align-left roboto mt-7 mt-5-xs">
          <div className="font-size-lg bold font-size-md-sm text-align-center">
            Bienvenue !{' '}
          </div>
          <div className="mt-5 font-size-md-sm font-size-lg">
            Inscrivez-vous gratuitement en renseignant l'adresse de votre lieu
            de résidence et rejoignez directement vos voisins !
          </div>
        </div>

        <div className="mt-5 mb-10">
          <label htmlFor={addressSearchId}>
            Saisissez votre adresse postale :
          </label>
          <AddressSearch
            id={addressSearchId}
            zoneId={null}
            placeholder="Mon adresse postale"
            platform={platform}
          />
        </div>
      </section>
    </SignUpLayout>
  );
};
